export default {
  PANELS: "Modules photovoltaïques",
  INVERTERS: "Onduleur(s)",
  INVERTERS_MONOPHASE: "Monophasés",
  INVERTERS_TRIPHASE_LTE_30KW: "Triphasés 0-30 kW",
  INVERTERS_TRIPHASE_GT_30KW: "Triphasés > Projets 30 kW",
  INVERTERS_HYBRID: "Hybride",
  INVERTERS_ACCESSORIES: "Accessoires onduleur",
  BATTERIES: "Batteries",
  WORKWEAR: "Workwear",
  DISCONNECTS: "Coffrets AC/DC",
  ACCESSORIES: "Accessoires",
  COMBINER_BOXES: "Boîtes de combinaison",
  MOUNTING_SYSTEMS: "Systèmes de fixation",
  SERVICE_PANELS: "Tableaux électriques",
  LOAD_CENTERS: "Disjoncteurs",
  DC_OPTIMISERS: "Optimiseurs DC",
  TILES: "Tuiles solaires",
  SMALL_PRODUCER_ADMINISTRATION: "Particuliers (<= 36 kVa)",
  LARGE_PRODUCER_ADMINISTRATION: "Grands producteurs (+ 36 kVa)",
  CONSUEL_ADMINISTRATION: "Consuel",
  GRID_CONNECTION_ADMINISTRATION: "Raccordement",
  MAYOR_ADMINISTRATION: "Urbanisme",
  FEATURED_ADMINISTRATION: "Featured administration",
  FEATURED_HARDWARE: "Featured hardware",
  FEATURED: "Featured",
}
